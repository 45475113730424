import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type UserPermissionsContextType = {
  userPermissions: any[];
  setUserPermissions: React.Dispatch<React.SetStateAction<any[]>>;
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  webApi: string;
};

const UserPermissionsContext = createContext<UserPermissionsContextType | undefined>(undefined);

interface UserPermissionsProviderProps {
  children: ReactNode;
}

export const UserPermissionsProvider: React.FC<UserPermissionsProviderProps> = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState<any[]>(() => {
    const savedPermissions = localStorage.getItem('userPermissions');
    return savedPermissions ? JSON.parse(savedPermissions) : [];
  });
  const [userData, setUserData] = useState<any>(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });

  const webApi = 'https://apinetwork.sctickets.in';

  useEffect(() => {
    try {
      localStorage.setItem('userPermissions', JSON.stringify(userPermissions));
    } catch (error) {
      console.error("Error saving userPermissions to localStorage:", error);
    }
  }, [userPermissions]);

  useEffect(() => {
    try {
      localStorage.setItem('userData', JSON.stringify(userData));
    } catch (error) {
      console.error("Error saving userData to localStorage:", error);
    }
  }, [userData]);

  return (
    <UserPermissionsContext.Provider value={{ userPermissions, setUserPermissions, userData, setUserData, webApi }}>
      {children}
    </UserPermissionsContext.Provider>
  );
};

export const useUserPermissions = () => {
  const context = useContext(UserPermissionsContext);
  if (!context) {
    throw new Error('useUserPermissions must be used within a UserPermissionsProvider');
  }
  return context;
};
