
export const all_routes = {
  // dashboard routes
 
  // auth routes routes
  login: "/login",

  //ui routes
  alert: "/ui-alert",
  accordion: "/ui-accordion",
  avatar: "/ui-avatar",
  badges: "/ui-badges",
  border: "/ui-border",
  breadcrums: "/ui-breadcrums",
  button: "/ui-buttons",
  buttonGroup: "/ui-button-group",
  cards: "/ui-cards",
  carousel: "/ui-carousel",
  colors: "/ui-colors",
  dropdowns: "/ui-dropdowns",
  grid: "/ui-grid",
  images: "/ui-images",
  lightbox: "/ui-lightbox",
  media: "/ui-media",
  modals: "/ui-modals",
  navTabs: "/ui-navtabs",
  offcanvas: "/ui-offcanvas",
  pagination: "/ui-pagination",
  placeholder: "/ui-placeholder",
  popover: "/ui-popover",
  progress: "/ui-progress",
  rangeSlider: "/ui-rangeslider",
  spinner: "/ui-spinner",
  sweetalert: "/sweetalert",
  toasts: "/ui-toasts",
  tooltip: "/ui-tooltip",
  typography: "/ui-typography",
  video: "/ui-video",
  clipboard: "/ui-clipboard",
  counter: "/ui-counter",
  dragandDrop: "/ui-drag-drop",
  rating: "/ui-rating",
  ribbon: "/ui-ribbon",
  stickyNotes: "/ui-sticky-notes",
  textEditor: "/ui-text-editor",
  timeLine: "/ui-timeline",
  scrollBar: "/ui-scrollbar",
  apexChat: "/ui-apexchat",
  chart: "/ui-chartjs",
  featherIcons: "/ui-feather-icon",
  falgIcons: "/ui-flag-icon",
  fantawesome: "/ui-fantawesome",
  materialIcon: "/ui-material-icon",
  pe7icon: "/ui-icon-pe7",
  simpleLineIcon: "/ui-simpleline",
  themifyIcon: "/ui-themify",
  typicon: "/ui-typicon",
  weatherIcon: "/ui-weather-icon",
  basicInput: "/forms-basic-input",
  checkboxandRadion: "/form-checkbox-radios",
  inputGroup: "/form-input-groups",
  gridandGutters: "/form-grid-gutters",
  formSelect: "/form-select",
  formMask: "/form-mask",
  fileUpload: "/form-fileupload",
  horizontalForm: "/form-horizontal",
  verticalForm: "/form-vertical",
  floatingLable: "/form-floating-labels",
  formValidation: "/form-validation",
  reactSelect: "/select",
  formWizard: "/form-wizard",
  dataTable: "/tables-basic",
  tableBasic: "/data-tables",
  iconicIcon: "/icon-ionic",

  //base-ui
  uiAlerts: "/ui-alerts",
  uiAccordion: "/ui-accordion",
  uiAvatar: "/ui-avatar",
  uiBadges: "/ui-badges",
  uiBorders: "/ui-borders",
  uiButtons: "/ui-buttons",
  uiButtonsGroup: "/ui-buttons-group",
  uiBreadcrumb: "/ui-breadcrumb",
  uiCards: "/ui-cards",
  uiCarousel: "/ui-carousel",
  uiColor: "/ui-color",
  uiDropdowns: "ui-dropdowns",


  //Academic module
  AcademicReason: "/academic/academic-reason",
  classSyllabus: "/academic/class-syllabus",
  classSubject: "/academic/class-subject",
  classSection: "/academic/class-section",
  classRoom: "/academic/class-room",
  classRoutine: "/academic/class-routine",
  sheduleClasses: "/academic/schedule-classes",
  classes: "/academic/classes",
  classHomeWork: "/academic/class-home-work",
  exam: "/academic/exam",
  examSchedule: "/academic/exam-schedule",
  grade: "/academic/grade",
  examResult: "/academic/exam-result",
  examAttendance: "/academic/exam-attendance",
  examType:"/academic/exam-type",
  examSubject:"/academic/exam-subject",
  examInstance:"/academic/exam-instance",
  studentExamSummary:"/academic/student-exam-summary",
  examGradeBoundary:"/academic/exam-grade-boundary",
  students:"/academic/students",
  branchAcademicYear: "/academic/branch-academic-year",
  branchClassName: "/academic/branch-Class-Name",
  branchSection: "/academic/branch-Section",
  branchOrientation: "/academic/branch-Orientation",
  branchState: "/academic/branch-State",
  branchZone: "/academic/branch-Zone",
  branches: "/academic/branches",
  classTimetable: "/academic/class-time-table",
  examResultEntry: "/academic/exam-result-entry",
  adminDashboard: "/academic/admin-dashboard",
  dualListBox: "/academic/dual-list-box",
  networkConnectionDetails: "/academic/network-connection-details",
  paymentDues: "/academic/payment-dues",
  tickets: "/academic/tickets",
  providerDetails: "/academics/provider-details",
  approvalStatusDetails: "/academics/approaval-status-details",
  paymentStatusDetails: "/academic/payment-status-details",
  deviceTypeDetails: "/academic/device-type-details",
  sectionDetail:"/academic/section-detail",
  billingCompanyDetails: "/academic/billing-company-details",
  adminDetail:"/academic/admin-details",
  enginnerDetail: "/academic/enginner-detail-table",
  groups: "/academic/groups",
  users: "/academic/users",
  solvedTickets: "/academic/solved-tickets",
  categoriesDetals: "/academic/categories-details",
  channelDetails: "academic/channel-details",
  serviceTypeDetails: "academic/service-type-details",
  subCategoryDetailTable: "academic/sub-category-detail-table",
};
