import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../core/common/dataTable/index";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import Cookies from "js-cookie";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { useUserPermissions } from "../../../UserPermissionsContext";

interface ErrorMessages {
  WAN_IP?: string[];
  LAN_IP?: string[];
  message?: string[];
  ISP_user_name?: string[];
  ISP_password?: string[];
  Device_Type?: string[];
  Device_serial_no?: string[];
  BW_capasity?: string[];
  which_section_connection_is_provided?: string[];
  price_PM_ex_GST?: string[];
  GST_per?: string[];
  price_PM_in_GST?: string[];
  total_price?: string[];
  billing_frequency_in_months?: string[];
  billing_company?: string[];
  last_payment_amount?: string[];
  last_payment_date?: string[];
  recharge_from?: string[];
  recharge_to?: string[];
  last_payment_duration?: string[];
  next_payment_due_date?: string[];
  approval_status?: number | null;
  approval_status_name?: string[];
  invoice_submitted_date?: string[];
  payment_status?: string[];
  IT_remarks?: string[];
  has_class_room_wifi?: string[];
  count_class_room_wifi?: string[];
  branch?: string[];
  ISP_provider_contact?: string[];
  Band_width?: string[];
  ISP_provider?: string[];
}

type Exam = {
  branch_connection_id: number;
  state: number;
  zone: number;
  state_name: any;
  zone_name: any;
  branch_name: any;
  ISP_provider: any;
  ISP_provider_name: any;
  GST_per: number | null;
  price_PM_in_GST: number | null;
  total_price: number | null;
  billing_frequency_in_months: number | null;
  billing_company: number | null;
  recharge_from: string | null;
  recharge_to: string | null;
  last_payment_duration: any;
  next_payment_due_date: string | null;
  approval_status: number | null;
  approval_status_name: string | null;
  invoice_submitted_date: string | null;
  payment_status: any;
  IT_remarks: any;
  has_class_room_wifi: boolean;
  count_class_room_wifi: string | null;
  branch: number;
  color: string;
  admin: number | null;
  admin_name: string | null;
  GST_per_value: string | null;
  billing_company_name: string | null;
  payment_status_name: string | null;
};
const PaymentDues = () => {
  const routes = all_routes;
  const [exams, setExams] = useState<Exam[]>([]);
  const jwtToken = Cookies.get("authToken");
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const defaultValue = dayjs(formattedDate);
  const [isLoading, setIsLoading] = useState(false);
  const [newExam, setNewExam] = useState<Exam>({
    branch_connection_id: 9,
    state_name: "",
    zone_name: "",
    branch_name: "",
    ISP_provider: "",
    ISP_provider_name: "",
    GST_per: null,
    price_PM_in_GST: null,
    total_price: null,
    billing_frequency_in_months: null,
    billing_company: null,
    recharge_from: null,
    recharge_to: null,
    last_payment_duration: null,
    next_payment_due_date: null,
    approval_status_name: null,
    invoice_submitted_date: null,
    payment_status: "",
    IT_remarks: "",
    has_class_room_wifi: true,
    count_class_room_wifi: null,
    branch: 0,
    color: "",
    zone: 0,
    state: 0,
    admin: null,
    admin_name: null,
    approval_status: null,
    GST_per_value: null,
    billing_company_name: null,
    payment_status_name: null,
  });
  const resetForm = () => {
    setNewExam({
      branch_connection_id: 9,
      state_name: "",
      zone_name: "",
      branch_name: "",
      ISP_provider: "",
      ISP_provider_name: "",
      GST_per: null,
      price_PM_in_GST: null,
      total_price: null,
      billing_frequency_in_months: null,
      billing_company: null,
      recharge_from: null,
      recharge_to: null,
      last_payment_duration: null,
      next_payment_due_date: null,
      approval_status_name: null,
      invoice_submitted_date: null,
      payment_status: "",
      IT_remarks: "",
      has_class_room_wifi: true,
      count_class_room_wifi: null,
      branch: 0,
      color: "",
      zone: 0,
      state: 0,
      admin: null,
      admin_name: null,
      approval_status: null,
      GST_per_value: null,
      billing_company_name: null,
      payment_status_name: null,
    });
    setEditingIndex(null);
    setResetSelect((prev) => !prev);
  };
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [resetSelect, setResetSelect] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [provider, setProvider] = useState([]);
  const [branches, setBranches] = useState([]);
  const [errorMessage, setErrorMessage] = useState<ErrorMessages>({});
  const [currentRecord, setCurrentRecord] = useState<Exam | null>(null);
  const [approvalStatus, setAprrovalStatus] = useState([]);
  const [state, setState] = useState([]);
  const [zone, setZone] = useState([]);
  const [selectedState, setSelectedState] = useState<number | null>(null);
  const [selectedZone, setSelectedZone] = useState<number | null>(null);
  const [admin, setAdmin] = useState([]);
  const [gstData, setGstData] = useState([]);
  const [billingCompanies, setBillingCompanies] = useState([]);
  const { userPermissions, webApi } = useUserPermissions();
  const [paymentStatus, setPaymentStatus] = useState([]);
  const navigate = useNavigate();
  const [from, setFrom] = useState();
  const [to, setTo] = useState<number>();
  const [totalCount,setTotalCount] = useState();
  const checkPermission = (action: any, subject: any) => {
    return userPermissions.some(
      (ability) => ability.action === action && ability.subject === subject
    );
  };
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  const fetchExams = async (_page: number) => {
    setIsLoading(true); // Start loading
    try {
      const response = await fetch(
        `${webApi}/provider/branch_connection_payment/?page=${_page}`,
        options
      );
      const data = await response.json();
      setTotalPages(data.total_pages);
      setExams(data.results.data);
      setFrom(data.from);
      setTo(data.to);
      setTotalCount(data.total_count)
    } catch (error) {
      console.error("Error fetching exams:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const fetchProviders = async () => {
    const response = await fetch(
      `${webApi}/provider/provider_details_dropdown/`,
      options
    );
    const data = await response.json();
    setProvider(
      data.map((item: { provider_id: number; name: any; label: any }) => ({
        value: item.name,
        label: item.label,
        id: item.provider_id,
        idName: "ISP_provider",
        name: "ISP_provider_name",
      }))
    );
  };

  const fetchApprovalStatus = async () => {
    const response = await fetch(
      `${webApi}/provider/approval_status_dropdown/`,
      options
    );
    const data = await response.json();
    setAprrovalStatus(
      data.map((item: { id: number; name: any; label: any }) => ({
        value: item.name,
        label: item.label,
        id: item.id,
        idName: "approval_status",
        name: "approval_status_name",
      }))
    );
  };

  const fetchAdmins = async () => {
    const response = await fetch(
      `${webApi}/provider/admins_dropdown/`,
      options
    );
    const data = await response.json();
    setAdmin(
      data.map((item: { id: number; name: string; label: string }) => ({
        value: item.name,
        label: item.label,
        id: item.id,
        idName: "admin",
        name: "admin_name",
      }))
    );
  };

  const fetchGstData = async () => {
    const response = await fetch(
      `${webApi}/provider/gst_percentages_dropdown/`,
      options
    );
    const data = await response.json();
    setGstData(
      data.map((item: { id: number; GST_value: string; label: string }) => ({
        value: item.GST_value,
        label: item.label,
        id: item.id,
        idName: "GST_per",
        name: "GST_per_name",
      }))
    );
  };

  const fetchBillingCompanies = async () => {
    const response = await fetch(
      `${webApi}/provider/billing_companies_dropdown/`,
      options
    );
    const data = await response.json();
    setBillingCompanies(
      data.map((item: { id: number; company: string; label: string }) => ({
        value: item.company,
        label: item.label,
        id: item.id,
        idName: "billing_company",
        name: "billing_company_name",
      }))
    );
  };

  const fetchPaymentStatus = async () => {
    const response = await fetch(
      `${webApi}/provider/payment_status_dropdown/`,
      options
    );
    const data = await response.json();
    setPaymentStatus(
      data.map((item: { name: any; label: any; id: any }) => ({
        value: item.name,
        label: item.label,
        id: item.id,
        idName: "payment_status",
        name: "payment_status_name",
      }))
    );
  };
  useEffect(() => {
    if (!checkPermission("view", "Can view BranchConnectionDetails")) {
      Cookies.remove("authToken");
      navigate("/login");
      return;
    }

    fetchExams(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (!checkPermission("view", "Can view BranchConnectionDetails")) {
      Cookies.remove("authToken");
      navigate("/login");
      return;
    }

    fetchProviders();
    fetchApprovalStatus();
    fetchAdmins();
    fetchGstData();
    fetchBillingCompanies();
    fetchPaymentStatus();
  }, []);

  const fetchStates = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    const response = await fetch(
      `${webApi}/branches/branches_state_dropdown/`,
      options
    );
    const stateData = await response.json();
    setState(
      stateData.results.map(
        (item: { name: any; label: any; state_id: any }) => ({
          value: item.name,
          label: item.label,
          id: item.state_id,
          idName: "state",
          name: "state_name",
        })
      )
    );
  };

  const fetchZones = async (stateId: number) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    const response = await fetch(
      `${webApi}/branches/branches_zone_dropdown/${stateId}/`,
      options
    );
    const zonesData = await response.json();
    setZone(
      zonesData.results.map(
        (item: { name: any; label: any; zone_id: any }) => ({
          value: item.name,
          label: item.label,
          id: item.zone_id,
          idName: "zone",
          name: "zone_name",
        })
      )
    );
  };

  const fetchBranches = async (stateId: number, zoneId: number) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    const response = await fetch(
      `${webApi}/branches/branches_branches_dropdown/${stateId}/${zoneId}/`,
      options
    );
    const branchesData = await response.json();
    setBranches(
      branchesData.results.map(
        (item: { name: any; label: any; branch_id: any }) => ({
          value: item.name,
          label: item.name,
          id: item.branch_id,
          idName: "branch",
          name: "branch_name",
        })
      )
    );
  };

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState !== null) {
      fetchZones(selectedState);
      setSelectedZone(null);
      setBranches([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState !== null && selectedZone !== null) {
      fetchBranches(selectedState, selectedZone);
    } else {
      setBranches([]);
    }
  }, [selectedZone]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedExams =
      editingIndex !== null
        ? exams.map((exam, index) =>
            index === editingIndex ? { ...newExam } : exam
          )
        : [...exams, { ...newExam }]; // Add new exam

    console.log(updatedExams);

    try {
      const requestOptions = {
        method: editingIndex !== null ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(newExam),
      };
      const url =
        editingIndex !== null
          ? `${webApi}/provider/branch_connection_details/${newExam.branch_connection_id}/`
          : `${webApi}/provider/branch_connection_details/`;
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        const errData = await response.json();
        setErrorMessage(errData);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
      setExams(updatedExams);
      await fetchExams(currentPage);
      resetForm();
      const submitBtn = document.getElementById("submit-btn");
      if (submitBtn) {
        submitBtn.setAttribute("data-bs-dismiss", "modal");
        submitBtn.click();
      }
      const submitBtn1 = document.getElementById("submit-btn1");
      if (submitBtn1) {
        submitBtn1.setAttribute("data-bs-dismiss", "modal");
        submitBtn1.click();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (currentRecord) {
      setNewExam({
        state_name: currentRecord.state_name,
        zone_name: currentRecord.zone_name,
        branch_name: currentRecord.branch_name,
        ISP_provider: currentRecord.ISP_provider,
        ISP_provider_name: currentRecord.ISP_provider_name,
        GST_per: currentRecord.GST_per,
        price_PM_in_GST: currentRecord.price_PM_in_GST,
        total_price: currentRecord.total_price,
        billing_frequency_in_months: currentRecord.billing_frequency_in_months,
        billing_company: currentRecord.billing_company,
        recharge_from: currentRecord.recharge_from,
        recharge_to: currentRecord.recharge_to,
        last_payment_duration: currentRecord.last_payment_duration,
        next_payment_due_date: currentRecord.next_payment_due_date,
        approval_status_name: currentRecord.approval_status_name,
        invoice_submitted_date: currentRecord.invoice_submitted_date,
        payment_status: currentRecord.payment_status,
        IT_remarks: currentRecord.IT_remarks,
        has_class_room_wifi: currentRecord.has_class_room_wifi,
        count_class_room_wifi: currentRecord.count_class_room_wifi,
        branch_connection_id: currentRecord.branch_connection_id,
        branch: currentRecord.branch,
        color: currentRecord.color,
        state: currentRecord.state,
        zone: currentRecord.zone,
        admin: currentRecord.admin,
        admin_name: currentRecord.admin_name,
        approval_status: currentRecord.approval_status,
        GST_per_value: currentRecord.GST_per_value,
        billing_company_name: currentRecord.billing_company_name,
        payment_status_name: currentRecord.payment_status_name,
      });
      setEditingIndex(currentRecord.branch_connection_id);
      setSelectedState(currentRecord.state);
      setSelectedZone(currentRecord.zone);
    }
  }, [currentRecord]);

  const editExam = async (record: Exam) => {
    const branchConnectionId = record.branch_connection_id; // Extract the id from the record

    try {
      const response = await fetch(
        `${webApi}/provider/branch_connection_details/${branchConnectionId}/`,
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCurrentRecord(data.data); // Set the fetched data to currentRecord
      console.log(data);
      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewExam({ ...newExam, [name]: value || null });
  };

  const handleSelectChange = (
    option: Option | null,
    idName: string,
    name: string
  ) => {
    setNewExam((prev) => ({
      ...prev,
      [idName]: option ? option.id : "",
      [name]: option ? option.value : "",
    }));
  };

  const handleStateChange = (option: Option | null) => {
    setSelectedState(option && option.id !== undefined ? option.id : null);
  };

  const handleZoneChange = (option: Option | null) => {
    setSelectedZone(option && option.id !== undefined ? option.id : null);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null, field: string) => {
    setNewExam((prev) => ({
      ...prev,
      [field]: date ? date.format("YYYY-MM-DD") : null,
    }));
  };

  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body;
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (_text: string, record: Exam) => (
        <div className="d-flex align-items-center">
          {checkPermission(
            "change",
            "Can change BranchConnectionPaymentDetails"
          ) && (
            <span title="Edit">
              <button
                className="btn btn-icon btn-sm btn-soft-info rounded-pill"
                onClick={() => editExam(record)}
                data-bs-toggle="modal"
                data-bs-target="#edit_Connection"
                style={{
                  backgroundColor: "#17a2b8",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              >
                <i className="feather-edit" />
              </button>
            </span>
          )}
        </div>
      ),
    },
    {
      title: "State",
      dataIndex: "state_name",
      sorter: (a: Exam, b: Exam) => a.branch_name.localeCompare(b.branch_name),
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Zone",
      dataIndex: "zone_name",
      sorter: (a: Exam, b: Exam) => a.branch_name.localeCompare(b.branch_name),
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Admin",
      dataIndex: "admin_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Provider Name",
      dataIndex: "ISP_provider_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "GST",
      dataIndex: "GST_per_value",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Price PM Inc.GST",
      dataIndex: "price_PM_in_GST",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total_price",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Billing frequency in Months",
      dataIndex: "billing_frequency_in_months",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Trust / company to which billing is done",
      dataIndex: "billing_company_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "From",
      dataIndex: "recharge_from",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "To",
      dataIndex: "recharge_to",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Last payment duration",
      dataIndex: "last_payment_duration",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Next Payment Due Date",
      dataIndex: "next_payment_due_date",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Approval status",
      dataIndex: "approval_status_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Invoice Submit date",
      dataIndex: "invoice_submitted_date",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "Payment Status /remarks",
      dataIndex: "payment_status_name",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
    {
      title: "IT Remarks",
      dataIndex: "IT_remarks",
      render: (text: string, record: Exam) => (
        <span style={{ color: `${record.color}` }}>{text}</span>
      ),
    },
  ];
  const Loader = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100%" }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
        minHeight: "100vh",
        overflowY: "auto",
        overflowX: "auto",
      }}
    >
      <div className="page-wrapper">
        <div
          className="content"
          style={{
            backgroundImage:
              "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
            minHeight: "99%",
            margin: "5px",
            marginTop: "15px",
            position: "relative",
            maxWidth: "99%",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Network Connections</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Payment Dues
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <style>
            {`
              .styled-card {
                border: 1px solid #ddd;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                max-width: 100%
              }
              .styled-card .card-body {
                padding: 20px;
              }
              .styled-card .ant-table {
                border-radius: 8px;
                overflow: hidden;
              }
              .styled-card .ant-table-thead > tr > th {
                background-color: #f5f5f5;
                font-weight: bold;
              }
              .styled-card .ant-table-tbody > tr > td {
                padding: 12px;
              }
              .styled-card .ant-table-pagination {
                margin: 16px 0;
              }
            `}
          </style>
          <div className="styled-card">
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              {isLoading ? (
                <Loader />
              ) : (
                <div
                  style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "70vh",
                  }}
                >
                  <Table
                    columns={columns}
                    dataSource={exams}
                    Selection={true}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    currentpage={currentPage}
                    from={from}
                    to={to}
                    totalCount = {totalCount}
                  />
                </div>
              )}
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      <>
        {/* Edit Exam */}
        <style>
          {`
            .custom-modal-size {
              max-width: 1800px;
              width: auto;
            }
            .light-violet-bg {
              background-color: #f8f9f9;
            }
            .modal-body {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
              gap: 20px;
              max-height: 80vh; 
              overflow-y: auto;
            }
            .dual-list-box-container {
              grid-column: span 2;
              width: 100%
            }
          `}
        </style>
        <div className="modal fade" id="edit_Connection">
          <div className="modal-dialog custom-modal-size">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Connection</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // onClick={resetForm}
                  id="submit-btn1"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form
                onSubmit={handleSubmit}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              >
                <div className="modal-body light-violet-bg">
                  <div className="mb-3">
                    <label className="form-label">State</label>
                    <CommonSelect
                      className="select"
                      options={state}
                      onChange={(option) => {
                        // handleSelectChange(option, "state", "state_name");
                        handleStateChange(option);
                      }}
                      reset={resetSelect}
                      disabled={true}
                      defaultValue={state.find(
                        (option: { value: string }) =>
                          option.value === newExam.state_name
                      )}
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label className="form-label">Zone</label>
                    <CommonSelect
                      className="select"
                      options={zone}
                      onChange={(option) => {
                        // handleSelectChange(option, "zone", "zone_name");
                        handleZoneChange(option);
                      }}
                      reset={resetSelect}
                      disabled={true}
                      defaultValue={zone.find(
                        (option: { value: string }) =>
                          option.value === newExam.zone_name
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Branch Name</label>
                    <CommonSelect
                      className="select"
                      options={branches}
                      onChange={(option) =>
                        handleSelectChange(option, "branch", "branch_name")
                      }
                      reset={resetSelect}
                      disabled={true}
                      defaultValue={branches.find(
                        (option: { value: string }) =>
                          option.value === newExam.branch_name
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Admin Name</label>
                    <CommonSelect
                      className="select"
                      options={admin}
                      onChange={(option) =>
                        handleSelectChange(option, "admin", "admin_name")
                      }
                      reset={resetSelect}
                      defaultValue={admin.find(
                        (option: { value: string }) =>
                          option.value === newExam.admin_name
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Provider Name</label>
                    <CommonSelect
                      className="select"
                      options={provider}
                      onChange={(option) =>
                        handleSelectChange(
                          option,
                          "ISP_provider",
                          "ISP_provider_name"
                        )
                      }
                      reset={resetSelect}
                      defaultValue={provider.find(
                        (option: { value: string }) =>
                          option.value === newExam.ISP_provider_name
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">GST</label>
                    <CommonSelect
                      className="select"
                      options={gstData}
                      onChange={(option) =>
                        handleSelectChange(option, "GST_per", "GST_per_value")
                      }
                      reset={resetSelect}
                      defaultValue={gstData.find(
                        (option: { value: string }) =>
                          option.value === newExam.GST_per_value
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Price PM Inc.GST</label>
                    <input
                      type="text"
                      className={`form-control`}
                      onChange={handleInputChange}
                      name="price_PM_in_GST"
                      placeholder="Price PM Inc.GST"
                      value={newExam.price_PM_in_GST || ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Total</label>
                    <input
                      type="text"
                      className={`form-control`}
                      onChange={handleInputChange}
                      name="total_price"
                      placeholder="Total"
                      value={newExam.total_price || ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Billing frequency in Months
                    </label>
                    <input
                      type="text"
                      className={`form-control`}
                      onChange={handleInputChange}
                      name="billing_frequency_in_months"
                      placeholder="Billing frequency in Months"
                      value={newExam.billing_frequency_in_months || ""}
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label className="form-label">Billing Company</label>
                    <CommonSelect
                      className="select"
                      options={billingCompanies}
                      onChange={(option) =>
                        handleSelectChange(
                          option,
                          "billing_company",
                          "billing_company_name"
                        )
                      }
                      reset={resetSelect}
                      defaultValue={billingCompanies.find(
                        (option: { value: string }) =>
                          option.value === newExam.billing_company_name
                      )}
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label className="form-label">Approval Status</label>
                    <CommonSelect
                      className="select"
                      options={approvalStatus}
                      onChange={(option) =>
                        handleSelectChange(
                          option,
                          "approval_status",
                          "approval_status_name"
                        )
                      }
                      reset={resetSelect}
                      defaultValue={approvalStatus.find(
                        (option: { value: string }) =>
                          option.value === newExam.approval_status_name
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Payment Status /remarks
                    </label>
                    <CommonSelect
                      className="select"
                      options={paymentStatus}
                      onChange={(option) =>
                        handleSelectChange(
                          option,
                          "payment_status",
                          "payment_status_name"
                        )
                      }
                      defaultValue={paymentStatus.find(
                        (option: { value: string }) =>
                          option.value === newExam.payment_status_name
                      )}
                      reset={resetSelect}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">IT Remarks</label>
                    <input
                      type="text"
                      className={`form-control`}
                      onChange={handleInputChange}
                      name="IT_remarks"
                      placeholder="IT Remarks"
                      value={newExam.IT_remarks}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">From</label>
                    <div className="date-pic">
                      <DatePicker
                        className="form-control datetimepicker"
                        format="YYYY-MM-DD"
                        getPopupContainer={getModalContainer}
                        defaultValue={defaultValue}
                        onChange={(date) =>
                          handleDateChange(date, "recharge_from")
                        }
                        value={
                          newExam.recharge_from
                            ? dayjs(newExam.recharge_from, "YYYY-MM-DD")
                            : null
                        }
                      />
                      {/* <span className="cal-icon">
                        <i className="ti ti-calendar" />
                      </span> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">To</label>
                    <div className="date-pic">
                      <DatePicker
                        className={`form-control datetimepicker ${
                          errorMessage.recharge_to ? "is-invalid" : ""
                        }`}
                        format="YYYY-MM-DD"
                        getPopupContainer={getModalContainer}
                        defaultValue={defaultValue}
                        onChange={(date) =>
                          handleDateChange(date, "recharge_to")
                        }
                        value={
                          newExam.recharge_to
                            ? dayjs(newExam.recharge_to, "YYYY-MM-DD")
                            : null
                        }
                      />
                      {errorMessage.recharge_to && (
                        <div className="invalid-feedback">
                          {errorMessage.recharge_to[0]}
                        </div>
                      )}
                      {/* <span className="cal-icon">
                        <i className="ti ti-calendar" />
                      </span> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Last Payment Duration</label>
                    <input
                      type="text"
                      className={`form-control`}
                      onChange={handleInputChange}
                      name="last_payment_duration"
                      placeholder="Last Payment Duration"
                      value={newExam.last_payment_duration || ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Next Payment Due Date</label>
                    <div className="date-pic">
                      <DatePicker
                        className={`form-control datetimepicker ${
                          errorMessage.next_payment_due_date ? "is-invalid" : ""
                        }`}
                        format="YYYY-MM-DD"
                        getPopupContainer={getModalContainer}
                        defaultValue={defaultValue}
                        onChange={(date) =>
                          handleDateChange(date, "next_payment_due_date")
                        }
                        value={
                          newExam.next_payment_due_date
                            ? dayjs(newExam.next_payment_due_date, "YYYY-MM-DD")
                            : null
                        }
                      />
                      {errorMessage.next_payment_due_date && (
                        <div className="invalid-feedback">
                          {errorMessage.next_payment_due_date[0]}
                        </div>
                      )}
                      {/* <span className="cal-icon">
                        <i className="ti ti-calendar" />
                      </span> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Invoice Submit date</label>
                    <div className="date-pic">
                      <DatePicker
                        className="form-control datetimepicker"
                        format="YYYY-MM-DD"
                        getPopupContainer={getModalContainer}
                        defaultValue={defaultValue}
                        onChange={(date) =>
                          handleDateChange(date, "invoice_submitted_date")
                        }
                        value={
                          newExam.invoice_submitted_date
                            ? dayjs(
                                newExam.invoice_submitted_date,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                      />
                      {/* <span className="cal-icon">
                        <i className="ti ti-calendar" />
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Edit Payment Due */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-danger"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};
export default PaymentDues;
